import React from "react";
import Img from "gatsby-image"
import { Link, graphql } from 'gatsby';
import Layout from "../layouts/layout";


const CaseStudies  = ({data: {caseStudies}}) =>  {

    const hero = {};
    hero.heroHeading = 'Publications';

    return(
        <Layout hero={hero}>
                <div className="container mx-auto px-4 mb-16">
                <ul className="flex-col sm:flex-row mb-8 leading-tight">
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/newsletter">MAN News</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/documentaries">Documentaries</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 border-mane-yellow border-b-2 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/case-studies">Case Studies</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/certificates">Certificates</Link>
                    </div>
                </ul>

                <div className="mt-4 md:flex flex-wrap justify-between">
                    {caseStudies && caseStudies.edges.map(({node}) => (
                        <div className="flex flex-col cards-four mb-4 sm:mb-8 sm:w-1/4 border-shadow mr-6 sm:ml-0" key={node.id}>
                            <Link to={`/case-study/${node.slug}`}>
                                <Img className="sm:h-40 lg:h-64 object-cover w-full" alt={node.title}
                                    fluid={node.previewImage && node.previewImage.fluid}
                                />
                                <div className="p-4">
                                    <h3 className="text-gray-800 text-base font-bold">{node.title}</h3>
                                </div>
                            </Link>
                        </div>
                        ))}
                    </div>

                </div>
        </Layout>
    )};
export const query = graphql`
query getCaseStudies {
   caseStudies: allContentfulCaseStudies (sort: {fields: id, order: DESC}) {
    edges {
        node {
            id
            slug
            title
            previewImage {
                id
                fluid {
                   ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
          }
        }
    }
}
`;


export default CaseStudies;


